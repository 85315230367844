import congesService from '@data/congesService';
import {
  clientParamsDefaultPayload,
  siteParamsDefaultPayload,
} from '../openDays/payloads';

export const clientParamsFetch = async () => {
  const response = await congesService.post(
    '/v1/clients/search',
    clientParamsDefaultPayload
  );
  return await response.data.data[0];
};

export const siteParamsFetch = async () => {
  const response = await congesService.post(
    '/v1/sites/search',
    siteParamsDefaultPayload
  );
  return await response.data.data;
};

export const siteParamsSearch = async (field, value) => {
  const search = {
    name: {
      search: {
        value,
      },
    },
    id: {
      filters: [
        {
          field: 'id',
          operator: '=',
          value,
        },
      ],
    },
  };
  const searchParams = search[field];
  const response = await congesService.post('/v1/sites/search', {
    ...siteParamsDefaultPayload,
    ...searchParams,
  });

  return await response.data.data[0];
};

export const searchAllSites = async (value = '', page = 1) => {
  const response = await congesService.post('/v1/sites/search', {
    includes: [...siteParamsDefaultPayload.includes],
    search: {
      value,
    },
    scopes: [
      {
        name: 'withoutDifferentClientSettings',
      },
    ],
    page,
  });
  return await response.data;
};

export const createSettingsSite = async (siteId) => {
  const response = await congesService.post(
    `/v1/sites/create-settings/${siteId}`
  );
  return response;
};
