import React, { useEffect } from 'react';
import SiteItem from './SiteItem';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';

function SiteList({ sites, search }) {
  const { selectSite, siteSelected } = useSettingLeaves();

  useEffect(() => {
    return () => selectSite(null);
  }, []);

  if (sites.length < 1) return <p className='text-center'>Aucun resultat </p>;

  return (
    <div className='h-[460px] flex flex-col overflow-y-auto gap-1 vertical-scrollbar'>
      {sites.map((site) => {
        const { id, name } = site;
        const handleClick = () => selectSite(site);
        const isSelected = siteSelected?.id === id;

        return (
          <SiteItem
            key={id}
            handleClick={handleClick}
            name={name}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
}

export default SiteList;
