import React from 'react';
import ParamsButton from '../../ui/Button';

function SiteItem({ handleClick, name, isSelected }) {
  const colors = {
    bgColor: isSelected ? 'bg-primary2' : 'bg-white hover:bg-gray-100',
    text: isSelected ? 'text-red-500' : 'black',
  };

  return (
    <ParamsButton
      key={name}
      handleClick={handleClick}
      slug={name}
      title={
        <p className={`truncate font-lato text-sm font-bold ${colors.text}`}>
          {name}
        </p>
      }
      variant={isSelected ? 'primary' : 'inactive'}
      className={`flex items-center p-2 rounded-md gap-2 w-full `}
    />
  );
}

export default SiteItem;
